
import Vue from "vue";
import DatePicker from "@/components/form-components/DatePicker.vue";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import ClientData from "@/modules/orders/components/ClientData.vue";
import Services from "@/modules/orders/components/Services.vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
import Client from "@/modules/orders/mixins/client";
import DocumentType from "@/modules/orders/mixins/documentType";
// import Totals from "@/modules/orders/components/order_types/CERT/Totals.vue";
import LiveSearch from "@/components/form-components/LiveSearch.vue";
import certGoods from "@/modules/orders/mixins/certGoods";
import MultipleLiveSearch from "@/components/form-components/MultipleLiveSearch.vue";

export default Vue.extend({
  name: "AtaCreateOrder",

  components: {
    Services,
    MultipleLiveSearch,
    LiveSearch,
    // Totals,
    ClientData,
    DatePicker,
    FileUploader
  },

  mixins: [Client, DocumentType, certGoods],

  props: {
    language: {
      type: String,
      default: "ru",
      required: true
    },
    serviceId: {
      required: true,
      type: Number
    },
    allocations: {
      required: true,
      type: Object
    }
  },

  directives: { mask },

  data: () => ({
    rules,
    loading: false as boolean,
    isSelectBeneficiary: false as boolean,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      parent_documents: [] as Array<any>,
      documents: [{ file_type: "certification" } as any] as Array<any>,
      details: {
        services: [] as Array<any>,
        goods: [] as Array<any>,
        region: "moldova",
        order_date: new Date().toISOString().substr(0, 10)
      } as any,
      order: {
        use_last_power_of_attorney: false as boolean,
        source: "operator",
        client_delegate: "",
        document_type: "new",
        client: { type: "" } as any,
        document_valid_from: new Date().toISOString().substr(0, 10),
        power_of_attorney: "beneficiary" as string
      } as any
    } as any,
    errorMessages: {} as any,
    regions: [
      { text: "Moldova", value: "moldova" },
      { text: "Transnistria", value: "transnistria" }
    ],
    selects: {
      cardTypes: [] as Array<SelectComponentInterface>,
      purpose: [] as Array<SelectComponentInterface>,
      packingCategories: [] as Array<SelectComponentInterface>,
      languages: [] as Array<SelectComponentInterface>,
      client_types: [] as Array<SelectComponentInterface>,
      release_types: [] as Array<SelectComponentInterface>,
      currencies: [] as Array<string>,
      employees: [] as Array<SelectComponentInterface>
    } as any,
    lang: store.getters["localization/getCurrent"],
    selectedTab: 0,
    totalSum: 0 as number,
    createBeneficiaryModal: false as boolean,
    isLoading: false,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    defaultCountry: null as any,
    forceKey: 0 as number,
    totalsKey: 0 as number,
    powerOfAttorney: null as any,
    panel: [0] as Array<number>,
    maxLength: 12,
    maxDocumentCounter: 20,
    isCreate: true as boolean,
    regulationConfirm: false as boolean,
    countryMock: [{ origin_country_id: 144 }]
  }),

  watch: {
    language: {
      immediate: true,
      handler() {
        this.model.order.language = this.language;
      }
    },
    "model.documents": {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    },
    "model.details.exchange_rate"() {
      this.$forceUpdate();
    },
    errorMessages() {
      Object.keys(this.errorMessages)
        .filter((key: string) => key.startsWith("details.goods."))
        .map(this.getErrorIndex);
    },
    "model.details.goods": {
      immediate: true,
      deep: true,
      handler() {
        this.panel.push(this.model.details.goods.length);
      }
    }
  },

  computed: {
    goodPriceLabel() {
      if (this.currency?.text) {
        return this.$t("orders.form.fields.unit_price", {
          currency: this.currency.text
        });
      }
      return this.$t("orders.form.fields.unit_price");
    },
    goodsSum() {
      return this.model.details.goods.reduce(
        (
          total: number,
          { price, quantity }: { price: number; quantity: number }
        ) => {
          if (price && quantity) {
            return total + price * quantity;
          }
          return total;
        },
        0
      );
    },
    currency() {
      return this.selects.currencies.find(
        (item: any) => item.value === this.model.details.currency_id
      );
    },
    computedRules(): object {
      return {
        goodsMassValidation: this.goodsMassValidation
      };
    },
    isMultipleSelect() {
      return this.model.order.document_type === "substitution";
    },
    currencyText() {
      return this.currency?.text || "";
    }
  },

  async mounted() {
    await this.loadData();
    await this.setCountry();
  },

  methods: {
    goodsMassValidation(index: number): any {
      if (!this.model.details.goods[index].massa_netto) {
        return true;
      }

      return (
        Number(this.model.details.goods[index].massa_netto) <=
          Number(this.model.details.goods[index].massa_brutto) ||
        this.$t("orders.form.fields.net_mass_error_message")
      );
    },
    getOrderSum(event: any) {
      this.totalSum = event;
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.language
        };

        this.model.order.service_type_id = this.serviceId;

        const response = await Promise.all([
          this.$API.purposeOfUse().getList(this.serviceId, headers),
          this.$API.orders().getOrderReleaseModes(),
          this.$API.clients().getClientTypes(),
          this.$API.orders().getCardTypes(this.serviceId),
          this.$API.orders().getLanguages(),
          this.$API.transportTypes().getList(headers),
          this.$API.employees().getList(
            {
              filter: { subdivisions: this.allocations.subdivision_id }
            },
            headers
          ),
          this.$API.goodsCategories().getList(),
          this.$API.countries().getList(headers),
          this.$API
            .measureUnits()
            .getList({ service_type_id: this.serviceId }, headers),
          this.$API.packingCategories().getList(headers),
          this.$API.currency().getList(),
          this.$API.measureUnits().getList(
            {
              service_type_id: this.serviceId,
              is_weight_unit: true
            },
            headers
          )
        ]);

        await this.setServerResponse(response);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(): Promise<void> {
      const form = this.$refs.form as Vue;
      this.isLoading = true;

      try {
        if (form.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );

          if (this.powerOfAttorney.file) {
            this.model.documents.push(this.powerOfAttorney);
          }

          const model = { ...this.model };
          model.order.service_type_id = this.serviceId;

          const { branch_id, subdivision_id, handler_id } = this.allocations;
          model.order.branch_id = branch_id;
          model.order.subdivision_id = subdivision_id;
          model.order.handler_id = handler_id;

          if (
            model.hasOwnProperty("parent_documents") &&
            Array.isArray(model.parent_documents)
          ) {
            model.parent_documents = model.parent_documents.map((item: any) => {
              if (item.hasOwnProperty("value")) {
                return item.value;
              }

              if (item.hasOwnProperty("id")) {
                return item.id;
              }

              return item;
            });
          }

          await this.$API.orders().create(model);
          form.removeGuard();
          await this.$router.push("/orders");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        } else {
          this.selectedTab = 0;
          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
        }
      } catch (e) {
        form.removeGuard();
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          if (e.errors.length === 1) {
            if (
              e.errors.hasOwnProperty("order") &&
              e.errors.order.hasOwnProperty("required_guaranty_sum")
            ) {
              this.selectedTab = 1;
            }
          } else if (e.errors.length) {
            this.selectedTab = 0;
          }
          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );
            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async setServerResponse(response: Array<any>) {
      const [
        purpose,
        releaseModes,
        clientTypes,
        cardTypes,
        languages,
        transportTypes,
        employees,
        goodsCategories,
        countries,
        units,
        packingCategories,
        currencies,
        weightUnits
      ] = response;

      this.selects.languages = languages.items;
      this.selects.purpose = purpose;
      this.selects.release_types = releaseModes.items;
      this.model.order.release_mode = releaseModes.default;
      this.selects.client_types = clientTypes;
      this.selects.cardTypes = cardTypes.items;
      this.selects.transportTypes = transportTypes.items;
      this.selects.employees = employees.items;
      this.model.details.employee_id = this.allocations.handler_id;
      this.selects.goodsCategories = goodsCategories;
      this.selects.countries = countries.items;
      this.selects.units = units;
      this.selects.weightUnits = weightUnits;
      this.selects.packingCategories = packingCategories;
      this.model.order.document_type = cardTypes.default;
      this.selects.currencies = currencies.items;

      const defaultCurrency = currencies.items.find(
          (item : any) => item.value === currencies.default
      );

      this.model.details.currency_id = defaultCurrency?.value || currencies.default;
      if (this.model.details.currency_id === 1) {
        this.model.details.exchange_rate = "1.0000";
      } else {
        this.model.details.exchange_rate = this.setExchangeRate(
            defaultCurrency || { value: this.model.details.currency_id, text: "" }
        );
      }

    },
    addDocument(): void {
      this.model.documents.push({ file_type: "certification" });
    },
    removeDocument(index: number): void {
      this.model.documents.splice(index, 1);
    },
    async onCurrencyChange(currency: { value: number; text: string }) {
      if (!currency || !currency.value) {
        return;
      }

      this.model.details.currency_id = currency.value;

      await this.setExchangeRate(currency);
    },
    async setExchangeRate(currency: SelectComponentInterface): Promise<void> {
      if (!currency || !currency.value) {
        return;
      }

      if (currency.text === "MDL") {
        this.model.details.exchange_rate = "1.0000";

        return;
      }

      try {
        const { value } = await this.$API
          .exchangeRates()
          .getExchangeRate(Number(currency.value));

        this.model.details.exchange_rate = value;
        this.$forceUpdate();
        this.forceKey++;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    isNumber(evt: any) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setCountry() {
      this.model.details.goods = this.countryMock;
    }
  }
});
